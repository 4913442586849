import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import FeedItems from "./FeedItems";
import { useAuth } from "../util/auth";

function FeedSection(props) {
  const auth = useAuth();

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="flex flex-wrap">
          <div className="p-4 w-full md:w-1/2">
            <div className="rounded border border-gray-200">
              <FeedItems />
            </div>
          </div>
          <div className="p-4 w-full md:w-1/2">
            <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
              <h3 className="mb-4">Welcome</h3>
              <p className="mb-1 text-blue-700">WARNING: Early preview, things will change. Consider any reviews you put in to be test data, subject to being deleted at any point.</p>
              <p>
                This is an early preview of One Word or Another, where anyone can provide their brief opinion.
              </p>
              <div>
                <p>Lots of ideas and things to do:</p>
                <ul>
                  <li className="line-through">Erm...make it so you can only put one word in</li>
                  <li>Swear filter, rubbish reducer and review flagging</li>
                  <li>Friendlier validation rather than a big orrible error</li>
                  <li className="line-through">Pick from known databases rather than enter any title</li>
                  <li className="line-through">Show proper covers</li>
                  <li>Onboarding flow</li>
                  <li>Unique mandatory usernames</li>
                  <li>click through to find out more about book/film/etc</li>
                  <li>Is your favourite book considered "meh" or "INCREDIBLE"?</li>
                  <li>Fun stuff to share your reviews</li>
                  <li>Friend groups/follows to see reviews from your crew</li>
                  <li>Social logins</li>
                  <li>A way nicer UI</li>
                  <li>Prizes/badges: first to review something. Unique review. In crowd review. Unique review that became popular. Binge review a series. Streaks. Good citizen (flags up naughty reviews). Early adopter. Top x reviewer.</li>
                  <li>Onboarding flow (create first review etc)</li>
                  <li>Get review privileges (extra word etc)</li>
                  <li>Decide if this is called 1 Word or Another, or One Word or Another.</li>
                </ul>
              </div>
              <h3 className="mb-4">Known bugs and annoyances</h3>
              <div>
                <p>Bugs are annoying</p>
                <ul>
                  <li>How/should I get Haruki Murakami's name to show in English rather than Chinese?</li>
                  <li>Top 10 results returned only might not get the required book. Allow search refinement, more results, search by Author?</li>
                </ul>
              </div>
              <h3 className="mb-4">Extra debug info</h3>
              {!auth.user && (<div>You are  not signed in.</div>)}
              {auth.user && (<div>You are signed in as <strong>{auth.user.email}</strong> | <strong>{auth.user.name}</strong>.</div>)}
              <h3 className="mb-4">Acknowledgements</h3>
              <p>Thanks to Cath and Vicki for inspiring this idea!</p>
              <p>Uses <a href="https://openlibrary.org">openlibrary.org</a> for book searching, book data, and book covers.</p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default FeedSection;
