import React, { useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import Button from "./Button";
import Autocomplete from "./AutoComplete";
// import { useAuth } from "./../util/auth";
import ReviewTypeSelector from "./ReviewTypeSelector";
import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/solid';
import ReviewItem from './ReviewItem';

function EditReviewModal(props) {
  //const auth = useAuth();
  const [reviewItem, setReviewItem] = useState(null);
  const [reviewStep, setReviewStep] = useState(1);
  const [reviewType, setReviewType] = useState("");

  const handleReviewItemSelect = (title, author, externalId) => {
    console.log("handleReviewItemSelect", title, author, externalId);
    setReviewItem({title, author, externalId});
    setReviewStep(reviewStep + 1);
  };

  const handleReviewTypeSelect = (type) => {
    setReviewType(type);
    setReviewStep(reviewStep + 1);
  }

  return (
    <Transition appear={true} show={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 h-full"
        onClose={() => props.onDone()}
      >
        <div className="px-4 min-h-screen max-h-full text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <span
            className="inline-block h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="inline-block p-6 my-8 w-full sm:max-w-md max-w-full text-left bg-white rounded-2xl shadow-xl transition-all transform">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                <div className="flex items-center justify-between">
                  {reviewStep > 1 && <Button size="xs" variant="link" onClick={() => setReviewStep(reviewStep - 1)} className="text-gray-400" startIcon={<ChevronLeftIcon className="inline-block w-3 h-3"/>}>Back</Button>}
                  <p className="text-sm text-gray-600 p-1">
                    {reviewStep === 1 && "Choose what you want to review"}
                    {reviewStep === 2 && `Find your ${reviewType}`}
                    {reviewStep === 3 && `Write your ${reviewType} review!`}
                  </p>
                  <Button size="xs" variant="link" onClick={() => props.onDone()} className="text-gray-400" endIcon={<XMarkIcon className="inline-block w-3 h-3"/>}>Close</Button>
                </div>
              </Dialog.Title>
              <div className="mt-4">

                <div className="space-y-4">
                  {reviewStep === 1 && 
                    <ReviewTypeSelector handleReviewTypeSelect={handleReviewTypeSelect} />
                  }
                  <div className={reviewStep === 2 ? "block" : "hidden"}>
                    <Autocomplete 
                      onSuggestionSelect={handleReviewItemSelect} />
                  </div>
                  {reviewStep === 3 &&
                    <ReviewItem 
                      reviewType={reviewType}
                      title={reviewItem.title}
                      author={reviewItem.author}
                      externalId={reviewItem.externalId}
                      onDone={() => props.onDone()}
                    />
                  }
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default EditReviewModal;
