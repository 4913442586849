import React, { useState } from "react";
import TextField from "./TextField";
import Button from "./Button";
import TitleAndAuthor from './TitleAndAuthor';
import FormAlert from "./FormAlert";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";
import { createReview } from "./../util/db";

export default function ReviewItem({title, author, externalId, reviewType, onDone}) {

  const auth = useAuth();
  const [formAlert, setFormAlert] = useState(null);
  const { register, handleSubmit, errors } = useForm();
  const [pending, setPending] = useState(false);
  

  const onSubmit = (userReview) => {
    const payload = {title, category:reviewType, review:userReview.review, externalId, author}
    setPending(true);
    const query = createReview({ owner: auth.user.uid, ...payload });

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        onDone();
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      {formAlert && (
        <div className="mb-4">
          <FormAlert
            type={formAlert.type}
            message={formAlert.message}
          />
        </div>
      )}
      <div className="flex flex-row gap-3">
        <div className="flex flex-col basis-3/4">
          <div className="flex-grow">
            <TitleAndAuthor title={title} author={author} />
          </div>
          <div className="flex">
            <TextField
              type="text"
              id="review"
              name="review"
              placeholder="Review in one word"
              error={errors.review}
              inputRef={register({
                required: "Please enter a review",
                minLength: {
                  value: 3,
                  message: "Bit short eh?"
                },
                maxLength: {
                  value: 20,
                  message: "Bit long eh?"
                },
                pattern: {
                  value: /^(?!.*([a-zA-Z])\1\1)(?:[A-Z][a-z]+|[a-z]+|[A-Z]{3,20})[!?]?$/,
                  message: "Reviews must look vaguely like a word"
                }
              })}
            />
          </div>
        </div>
        <div className="basis-1/4">
          <img
            className="h-32"
            alt={`Cover for ${title}`}
            src={`https://covers.openlibrary.org/b/olid/${externalId}-M.jpg`}
          />
        </div>
      </div>

      <div className="space-x-2 flex items-stretch">
        <Button
          type="submit"
          size="md"
          disabled={pending}
          isBlock={true}
          className="w-20"
        >
          {!pending && <>Save</>}
        </Button>
      </div>
    </form>
  );
}