import React, { useState } from "react";
import FormAlert from "./FormAlert";
import Button from "./Button";
import EditReviewModal from "./EditReviewModal";
import { useAuth } from "../util/auth";
import { useReviews } from "../util/db";
import FeedReviewItem from "./FeedReviewItem"

function FeedItems(props) {
  const auth = useAuth();

  const {
    data: reviews,
    status: reviewsStatus,
    error: reviewsError,
  } = useReviews();
console.log(reviews);
  const [creatingReview, setCreatingReview] = useState(false);

  const reviewsAreEmpty = !reviews || reviews.length === 0;

  return (
    <>
      {reviewsError && (
        <div className="mb-4">
          <FormAlert type="error" message={reviewsError.message} />
        </div>
      )}

      <div>
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          {!auth.user && ( 
            <div className="prose prose-a:text-blue-600 max-w-none">
              <a href="/auth/signin">Sign in</a> or <a href="/auth/signup">sign up</a> to share one word reviews
            </div>
          )}
          {auth.user && (
            <>
              <span className="text-xl">Reviews</span>
              <Button
                size="sm"
                variant="primary"
                onClick={() => setCreatingReview(true)}
              >
                Add Review
              </Button>
            </>
          )}
        </div>

        {(reviewsStatus === "loading" || reviewsAreEmpty) && (
          <div className="p-8">
            {reviewsStatus === "loading" && <>Loading...</>}

            {reviewsStatus !== "loading" && reviewsAreEmpty && (
              <>Nothing yet. Click the button to add your first review.</>
            )}
          </div>
        )}

        {reviewsStatus !== "loading" && reviews && reviews.length > 0 && (
          <>
            {reviews.map((review, index) => (
              <FeedReviewItem review={review} key={index}/>
            ))}
          </>
        )}
      </div>

      {creatingReview && <EditReviewModal onDone={() => setCreatingReview(false)} />}
    </>
  );
}

export default FeedItems;
