import React from "react";

const TitleAndAuthor = ({ title, author }) => (
  <>
    <p className="text-gray-600 line-clamp-2">{title}</p>
    <p className="text-gray-400 font-semibold tracking-wide text-xs uppercase truncate">
      {author}
    </p>
  </>
);

export default TitleAndAuthor;
