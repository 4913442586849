import React from "react";
import Meta from "./../components/Meta";
import FeedSection from "../components/FeedSection";

function FeedPage(props) {
    return (
        <>
            <Meta title="Home" />
            <FeedSection
                title="One word reviews"
                subtitle="OF ANYTHING!"
                strapline="One word or another"
                size="md"
                bgColor="bg-white"
            />
        </>
    );
}

export default FeedPage;
