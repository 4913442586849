import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import CtaSection from "./../components/CtaSection";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="We're gonna get ya" />
      <HeroSection2
        title={
            <span className="font-light">One Word Or Another</span>
        }
        subtitle="Reviewing the world. One word at a time."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        leftImage="https://images.unsplash.com/photo-1522148543752-8cdaa654a796?crop=entropy&fit=crop&h=800&w=1280"
        rightImage="https://images.unsplash.com/photo-1429962714451-bb934ecdc4ec?crop=entropy&fit=crop&h=800&q=80&w=600"
      />
      <CtaSection
        title={
          <>
            Create an account <span className="text-blue-600">today</span>!
          </>
        }
        subtitle="Get great gumbo given gratefully."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default AboutPage;
