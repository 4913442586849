import React from "react";
import TitleAndAuthor from "./TitleAndAuthor";
import QuoteIcon from "./QuoteIcon";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

function FeedReviewItem(props) {
    const { category, title, author, externalId, review, users } = props.review;
    
    return (
        <>
            <div className="w-full flex gap-3 p-4 content-center cursor-pointer hover:bg-gray-100 border-b border-t-gray-200 border-l-gray-200 border-r-gray-200">
                <div className="content-center flex-none">
                    {category && <img
                        className="w-16"
                        alt={`Cover for ${title}`}
                        src={`https://covers.openlibrary.org/b/olid/${externalId}-M.jpg`}
                    />}
                </div>
                <div className="flex flex-col grow">
                    <div className="flex-none">
                        {users && users.name && <p className="text-sm italic text-gray-400">@{users.name}</p>}
                    </div>
                    <div className="flex grow">
                        <div className="flex-none content-center">
                            <QuoteIcon className="w-6 text-gray-600" />
                        </div>
                        <div className="content-center">
                            <p className="font-serif text-xl italic text-gray-700">{review}</p>
                        </div>
                    </div>
                    <div className="shrink">
                        <TitleAndAuthor title={title} author={author} />
                    </div>
                </div>
                <div className="flex-none content-center">
                    <ChevronRightIcon className="w-5 h-5 text-gray-400 align-middle" />
                </div>
            </div>
        </>
    )
}

export default FeedReviewItem;