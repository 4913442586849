import React, { useState, useEffect, useRef } from 'react';
import TextField from './TextField';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import TitleAndAuthor from './TitleAndAuthor';

const Autocomplete = ({ onSuggestionSelect }) => {
    const [inputValue, setInputValue] = useState('');
    const [isSearching, setSearching] = useState(false);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const debounceTimeout = useRef(null);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (inputValue.length < 2) {
            setFilteredSuggestions([]);
            return;
        }

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        const timeout = setTimeout(() => {
            fetchSuggestions(inputValue);
        }, 500);

        debounceTimeout.current = timeout;

        return () => clearTimeout(timeout);
    }, [inputValue]);

    const fetchSuggestions = async (query) => {
        if(query.length < 2) {
            setErrorMessage("Please enter at least 2 characters");
        } else {
            setErrorMessage("");
            try {
                setSearching(true);
                const response = await fetch(`https://openlibrary.org/search.json?fields=title,author_name,cover_edition_key,edition_key&title=${query}`);
                setSearching(false);
                const data = await response.json();
                const count = data ? data.numFound : 0;
                if(count === 0) {
                    setErrorMessage("No results found");
                } else {
                    const results = data.docs
                        .filter(({ cover_edition_key }) => cover_edition_key) // Filter out records without cover_edition_key
                        .slice(0, 10)
                        .map(({ title, author_name, cover_edition_key }) => ({
                            title,
                            author: author_name ? author_name[0] : "Unknown Author",
                            externalId: cover_edition_key,
                        }))
                        .sort((a, b) => a.title.localeCompare(b.title));
                    setFilteredSuggestions(results);
                }
            } catch (error) {
                setErrorMessage(`Error fetching suggestions:${error}`);
            }
        }
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSuggestionClick = ({title, author, externalId}) => {
        onSuggestionSelect(title, author, externalId);
    };

    return (
        <div className="autocomplete">
            <div className="flex gap-4">
                <TextField
                    className="w-2/3"
                    type="search"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Search titles..."
                />
                <Button size="sm" onClick={() => fetchSuggestions(inputValue)} disabled={isSearching} className="w-1/3">{!isSearching ? "Search" : <LoadingIcon className="w-5 h-5 text-gray-400 animate-spin" />}</Button>
            </div>
            {errorMessage !== "" && <p className="text-gray-400 text-xs uppercase">{errorMessage}</p>}
            {filteredSuggestions.length > 0 && (
                <>
                    <p className="text-gray-400 text-xs uppercase pt-4 pb-1 pl-2">
                        {errorMessage !== "" ? errorMessage : "Search Results"}
                    </p>
                    <div className="suggestions h-96 overflow-auto border border-b-gray-200">
                        {filteredSuggestions.map((suggestion, index) => (
                            <div
                                key={index}
                                className="w-full flex gap-3 p-4 content-center cursor-pointer hover:bg-gray-100 border-b border-t-gray-200 border-l-gray-200 border-r-gray-200"
                                onClick={() => handleSuggestionClick(suggestion)}
                            >
                                <div className="content-center flex-none">
                                    <img
                                        alt={`Cover for ${suggestion.title}`}
                                        className="h-14 w-10" src={`https://covers.openlibrary.org/b/olid/${suggestion.externalId}-S.jpg`} 
                                     />
                                </div>
                                <div className="flex-grow">
                                    <TitleAndAuthor title={suggestion.title} author={suggestion.author} />
                                </div>
                                <div className="flex-none content-center">
                                    <ChevronRightIcon className="w-5 h-5 text-gray-400 align-middle" />
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
            
    );
};

export default Autocomplete;