import React from 'react';
import Button from './Button';
import { BookOpenIcon, FilmIcon, TvIcon, MusicalNoteIcon } from '@heroicons/react/24/solid'; 

const ReviewTypeSelector = ({ handleReviewTypeSelect }) => {
  return (
    <>
      <div className="space-x-3 space-y-3">
        <Button
          size="md"
          variant="outline"
          onClick={() => handleReviewTypeSelect("book")}
          startIcon={<BookOpenIcon className="inline-block w-5 h-5" />}
        >
          Book
        </Button>
      </div>
      <p className="text-sm text-gray-600 p-1">Coming soon:</p>
      <div className="space-x-3">
        <Button
          size="md"
          variant="outline"
          disabled
          startIcon={<FilmIcon className="inline-block w-5 h-5" />}
        >
          Movie
        </Button>
        <Button
          size="md"
          variant="outline"
          disabled
          startIcon={<TvIcon className="inline-block w-5 h-5" />}
        >
          TV Show
        </Button>
        <Button
          size="md"
          variant="outline"
          disabled
          startIcon={<MusicalNoteIcon className="inline-block w-5 h-5" />}
        >
          Music
        </Button>
      </div>
    </>
  );
};

export default ReviewTypeSelector;